<template>
  <v-container fluid class="pt-0" style="height: 100vh;">
    <v-row justify="center" align="center" style="height: 100%">
      <v-col cols="12" class="fill-height">
        <v-card elevation="0">
          <v-tabs class="custom-tabs" color="success" v-model="tabs">
            <v-tab>Основне</v-tab>
            <v-tab>Результат</v-tab>

            <v-tab-item class="pa-2" style="background-color: #f5f5f5 !important;">
              <v-card elevation="2">
                <v-card-title style="font-size: 1.2rem; text-transform: uppercase">
                  Налаштування імпорту абонентів із EPS
                </v-card-title>
                <v-card-text class="pt-0">
                  <v-row class="dense-wrapper">
                    <v-col cols="12" md="6">
                      <v-file-input
                          v-model="inputFile"
                          placeholder="Оберіть файл для імпорту абонентів"
                          label="Файл з абонентами"
                          show-size
                          hide-details
                          filled
                          prepend-icon=""
                          :color="!inputFile ? 'red' : 'grey'"
                          accept=".dbf"
                      >
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="config.code_page" :items="code_page_select"
                                label="Кодування" filled hide-details
                                :class="!config.code_page ? 'req-star' : ''"
                                color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <AddressElementSelect v-model="config.city_id" req
                                            label="Населений пункт" filled
                                            select_type="city"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="config.flat_type" :items="flat_ownership_types_select"
                                label="Тип власності" filled hide-details
                                :class="!config.flat_type ? 'req-star' : ''"
                                color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-checkbox v-model="config.person_id_from_file" label="Особовий рахунок з файла"
                                  color="secondary" hide-details class="mt-2"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="config.person_id_start" label="Початковий особовий рахунок"
                                    :class="!config.person_id_start && !config.person_id_from_file? 'req-star' : ''"
                                    v-integer
                                    filled
                                    :disabled="config.person_id_from_file"
                                    color="grey" hide-details class="mt-2"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <date-component v-model="config.date_start"
                                      :class_="!config.date_start ? 'req-star' : ''"
                                      req
                                      label="Дата початку"/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-checkbox v-model="config.flat_number_required" label="Номер квартири - обов’язкове поле"
                                  color="secondary" hide-details class="mt-2"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text class="pt-0">
                  <v-btn block class="success--text mb-3" @click.stop="importFile" :disabled="!headerReady()">
                    Імпортувати дані
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item class="grey lighten-5 pa-2" style="background-color: #f5f5f5 !important;">
              <v-card elevation="2">
                <v-card-text class="pb-0" style="display: flex">
                  <v-alert type="error" color="error lighten-1 mr-3" v-if="error" style="flex: 1">
                    {{ error }}
                  </v-alert>
                  <v-alert
                      type="success" dense
                      v-else
                      class="mr-3"
                      style="flex: 1">
                    Усі вулиці із файла створені...
                  </v-alert>
                  <v-btn class="success--text" @click.stop="createFlats" style="flex: 0 0 180px !important;" v-if="!error">
                    Створити абонентів
                  </v-btn>
                </v-card-text>
                <v-card-text class="pt-0">
                  <div class="form-table-custom mt-4" style="min-height: 120px">
                    <table style="width: 100%">
                      <thead>
                        <tr>
                          <th style="width: 50px;">№ рядка</th>
                          <th>Назва вулиці</th>
                          <th style="width: 50px">Код вулиці</th>
                          <th style="width: 50px">№ будинку</th>
                          <th style="width: 50px">Корпус</th>
                          <th style="width: 50px">№ квартири</th>
                          <th style="width: 50px">Дріб</th>
                          <th style="width: 100px">Особовий рахунок</th>
                          <th>Власник</th>
                          <th style="width: 100px">Зареєстровано</th>
                          <th style="width: 100px">Площа</th>
                          <th style="width: 100px">Кімнат</th>
                          <th style="width: 100px">Телефон</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, idx) in filtered_items" :key="`row-${idx}`">
                          <td style="width: 50px;">
                            <input type="text" v-integer disabled :value="item.row_number + 1">
                          </td>
                          <td>
                            <input type="text" disabled v-model="item.street_name" style="text-align: left">
                          </td>
                          <td style="width: 50px">
                            <input type="text" v-integer
                                   v-model.number="item.street_id"
                                   :class="!item.street_id ? 'input-error' : ''"
                            >
                          </td>
                          <td style="width: 50px">
                            <input type="text" v-model="item.building_number"
                                   :class="!item.building_number ? 'input-error' : ''"
                            >
                          </td>
                          <td style="width: 50px">
                            <input type="text" v-model="item.corpus">
                          </td>
                          <td style="width: 50px">
                            <input type="text" v-model="item.flat_number"
                                   :class="!item.flat_number && flat_number_required ? 'input-error' : ''"
                            >
                          </td>
                          <td style="width: 50px">
                            <input type="text" v-model="item.drib">
                          </td>
                          <td style="width: 100px">
                            <input type="text" v-model="item.person_id"
                                   :class="!item.person_id ? 'input-error' : ''"
                            >
                          </td>
                          <td style="text-align: left">
                            <input type="text" v-model="item.fio" style="text-align: left"
                                   :class="!item.fio ? 'input-error' : ''"
                            >
                          </td>
                          <td style="width: 100px">
                            <input type="text" v-integer v-model.number="item.persons">
                          </td>
                          <td style="width: 100px">
                            <input type="text" v-model.number="item.square" v-decimal>
                          </td>
                          <td style="width: 100px">
                            <input type="text" v-integer v-model.number="item.rooms">
                          </td>
                          <td style="width: 100px">
                            <input type="text" v-model="item.phone">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="pay-table-pagination d-flex justify-start" v-if="items.length">
                    <div class="pa-3 d-flex align-center justify-start">
                      <v-btn text depressed icon class="mr-1"
                             @click.stop="pageStartOrEnd(true)"
                             :disabled="items_per_page > items.length || page === 1"
                      >
                        <v-icon>mdi-chevron-double-left</v-icon>
                      </v-btn>
                      <v-btn text depressed icon
                             @click.stop="changePage(true)"
                             :disabled="page === 1"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <div class="mx-2">
                        <v-chip>
                          {{ `Сторінка: ${page} із ${pages}` }}
                        </v-chip>
                      </div>
                      <v-menu top :close-on-click="true" :close-on-content-click="true">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" class="mr-2">
                            {{ `${items_per_page} на стор.` }}
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item
                              v-for="(item, index) in items_per_page_list"
                              :key="index"
                              @click="setItemsPerPage(item)"
                          >
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-btn text depressed icon class="mr-1"
                             @click.stop="changePage(false)"
                             :disabled="items_per_page > items.length || page === pages"
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-btn text depressed icon
                             @click.stop="pageStartOrEnd(false)"
                             :disabled="page === pages"
                      >
                        <v-icon>mdi-chevron-double-right</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import epsAPI from "@/utils/axios/import_files";
import organizationAPI from "@/utils/axios/organization"
import {flat_ownership_types_select} from "@/utils/icons"
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {FETCH_STREETS} from "@/store/actions/city";

export default {
  name: "EpsBaseImport",
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      flat_ownership_types_select,
      inputFile: null,
      items: [],
      streets_items: [],
      error: '',
      code_page_select: [
        { text: 'Windows 1251', value: 'cp1251' },
        { text: 'DOS 866', value: 'cp866' },
      ],
      config: {
        code_page: 'cp1251',
        city_id: null,
        person_id_from_file: true,
        person_id_start: 0,
        flat_type: null,
        flat_number_required: true,
        date_start: null
      },
      pages: 0,
      items_per_page: 19,
      page: 0,
      items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
      tabs: 0
    }
  },
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization',
      current_month: 'getCurrentMonth',
      streets: 'getStreets'
    }),
    filtered_items() {
      return this.items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
    }
  },
  methods: {
    headerReady(return_object=false) {
      let ready = true
      let text_arr = []
      let text = ''

      if (!this.config.city_id) {
        text_arr.push('Населений пункт')
        ready = false
      }
      if (!this.config.code_page) {
        text_arr.push('Кодування')
        ready = false
      }
      if (!this.config.flat_type) {
        text_arr.push('Тип власності')
        ready = false
      }
      if (!this.config.date_start) {
        text_arr.push('Дата початку')
        ready = false
      }
      if (!this.config.person_id_from_file && !this.config.person_id_start) {
        text_arr.push('Початковий особовий рахунок')
        ready = false
      }

      if (text_arr.length) {
        text = text_arr.join(', ') + ' - мають бути завповнені'
      }

      if (return_object) {
        return {
          ready,
          text,
        }
      } else {
        return ready
      }

    },
    tableReady(return_object=false) {
      const text_arr = []
      let ready = true

      this.items.filter(item => !item.street_id || !item.building_number || !item.flat_number || !item.fio)
          .forEach(i => {
            const arr = []
            if (!i.street_id) {
              arr.push('Код вулиці')
            }
            if (!i.building_number) {
              arr.push('Номер будинку')
            }
            if (!i.flat_number) {
              arr.push('Номер квартири')
            }
            if (!i.fio) {
              arr.push('Власник')
            }
            text_arr.push(`Рядок № ${i.row_num + 1}. ${arr.join(', ')} - не заповнено`)
          })

      if (text_arr.length) {
        ready = false
      }

      if (return_object) {
        return {
          ready,
          text: text_arr.join('; ')
        }
      } else {
        return ready
      }

    },
    checkStreets() {
      let text = ''
      Object.keys(this.streets_items).forEach(key => {
        const street_name = this.streets_items[key] || 0
        const street_id = +key || -1
        const street = this.streets.find(item => item.city_id === this.config.city_id && item.code === street_id)
        if (!street) {
          text += `Вулиця: ${street_name}, код: ${street_id};`
        }
      })
      if (text) {
        text += ' - ці вулиці відсутні у довіднику. Спершу створіть ці вулиці...'
      }
      return text
    },
    importFile() {
      if (!this.headerReady()) return
      if (this.inputFile) {
      const formData = new FormData()
      formData.append('config', JSON.stringify(this.config))
      formData.append('file', this.inputFile)
      epsAPI.upload_eps_base_import_file(formData)
        .then(response => response.data)
        .then(data => {
          this.items = data.rows.map(item => {
            return Object.assign({}, item, {})
          })
          this.streets_items = data.streets
          this.setPageSplitter()
          this.error = this.checkStreets()
          this.tabs = 1
          this.$store.commit(ALERT_SHOW,
              {text: 'Дані оброблені успішно. Тепер перевірте їх та створіть абонентів...', color: 'success'})
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
        })
      } else {
        this.$store.dispatch(ALERT_SHOW, {text: 'Оберіть файл та вкажіть налаштування', color: 'error'})
      }
    },
    createFlats() {
      if (!this.headerReady()) return
      const table_ready = this.tableReady(true)
      if (table_ready.text) {
        this.$store.commit(ALERT_SHOW, {text: table_ready.text, color: 'error lighten-1'})
      }

      const payload = {
        rows: this.items,
        config: JSON.stringify(this.config)
      }

      organizationAPI.create_flats_from_eps_base(payload)
        .then(response => response.data)
        .then(data => {
          this.$store.commit(ALERT_SHOW,
              {text: `Створено: ${data.created} із ${data.created + data.not_created}`, color: 'success'})
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
        })
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      this.pages = Math.ceil(this.items.length / this.items_per_page)
      this.page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > this.items.length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
  },
  created() {
    this.$store.dispatch(FETCH_STREETS)
  }
}
</script>

<style scoped lang="scss">
  .custom-tabs {
    :deep(.v-window.v-item-group.theme--light) {
      background-color: transparent;
    }

    :deep(div[role="tablist"]) {
      background-color: #f5f5f5 !important;
    }
  }

  .col-name {
    font-size: .76rem;
    line-height: .72rem;
    color: #2f8232;
    margin-top: 3px;
  }
  .col-value {
    font-size: .82rem;
    font-weight: 500;
    color: #5e5e5e;
  }
</style>